import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import LogRocket from 'logrocket'
import { useState } from 'react'

const UpgradeModal = ({ open, setOpen, startCheckoutSession }) => {
  const [loading, setLoading] = useState(false)
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setOpen(false)}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-10 px-12 pb-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-700 border-4 border-indigo-500 shadow-2xl rounded-2xl">
              <Dialog.Title as="h3" className="text-3xl font-semibold leading-6 text-white">
                Upgrade to Pro?
              </Dialog.Title>
              <div className="mt-6 ml-2">
                <div className="flex items-center mb-1 text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mr-1"
                    viewBox="0 0 20 20"
                    fill="#7260ED"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Unlimited Queue
                </div>
                <div className="flex items-center mb-1 text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mr-1"
                    viewBox="0 0 20 20"
                    fill="#7260ED"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Tweet up to 5 times per day
                </div>
                <div className="flex items-center mb-1 text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mr-1"
                    viewBox="0 0 20 20"
                    fill="#7260ED"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Editable Tweets
                </div>
                <div className="flex items-center mb-1 text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mr-1"
                    viewBox="0 0 20 20"
                    fill="#7260ED"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Tweet Metrics
                </div>
              </div>

              <div className="flex justify-end w-full mt-8">
                <button
                  type="button"
                  className="inline-flex justify-center py-3 mr-3 font-semibold text-white bg-gray-600 rounded-md px-9 text-md hover:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  style={{
                    background:
                      'linear-gradient(45deg, rgba(173,71,214,1) 0%, rgba(78,71,220,1) 100%)',
                    boxShadow: '0px 10px 40px #7A4BD140',
                  }}
                  className="inline-flex justify-center py-3 font-semibold text-white transition duration-300 ease-in-out delay-150 bg-blue-100 rounded-md hover:-translate-y-1 hover:scale-110 px-9 text-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={() => {
                    LogRocket.track('Clicked Upgrade')
                    startCheckoutSession()
                    setLoading(true)
                  }}
                >
                  {loading ? 'Loading...' : 'Upgrade'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default UpgradeModal
