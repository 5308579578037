import React, { Component } from 'react'
import LogRocket from 'logrocket'

import { withAuthorization } from '../Session'
import { withFirebase } from '../Firebase'
import { compose } from 'recompose'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { formatDistance } from 'date-fns'
import ContentEditable from 'react-contenteditable'

import { IconContext } from 'react-icons'
import { FaRegHeart, FaRegComment } from 'react-icons/fa'
import { FiRepeat } from 'react-icons/fi'
import UpgradeModal from '../Shared/UpgradeModal'

const INITIAL_STATE = {
  queue: [],
  feed: [],
  deletedTweets: [],
  tweetsLoaded: false,
  subscriptionLevel: 'basic',
  modalOpen: false,
}

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  componentDidMount() {
    this.props.firebase.doGetUserData().then((response) => {
      this.setState({
        queue: response.data().queue,
        feed: response.data().feed,
        deletedTweets: response.data().deletedTweets,
        tweetsLoaded: true,
      })
      LogRocket.identify(response.data().username)
    })

    this.props.firebase.doGetUserSubscriptionLevel().then((response) => {
      if (response) {
        this.setState({ subscriptionLevel: response })
      }
    })

    this.props.firebase.doUpdateLastActive(new Date()).catch((error) => {
      console.log(error)
    })
  }

  onAddToQueue = (tweetIndex) => {
    let { feed, queue } = this.state

    if (queue.length < 7 || this.state.subscriptionLevel === 'pro') {
      let tweet = feed[tweetIndex]

      queue.push(tweet)

      this.props.firebase.doUpdateQueue(queue).catch((error) => {
        console.log(error)
      })

      feed.splice(tweetIndex, 1)

      this.setState({ queue: queue, feed: feed })

      this.props.firebase.doUpdateFeed(feed).catch((error) => {
        console.log(error)
      })

      toast.success('Added to queue!')
      LogRocket.track('Added to queue')
    } else {
      this.setState({ modalOpen: true })
      LogRocket.track('Maxed out queue')
    }
  }

  onDelete = (removeIndex) => {
    let { feed, deletedTweets } = this.state

    let deletedTweet = feed[removeIndex].id + ''

    deletedTweets.push(deletedTweet)

    this.props.firebase.doUpdateDeletedTweets(deletedTweets).catch((error) => {
      console.log(error)
    })

    feed.splice(removeIndex, 1)

    this.setState({ feed: feed })

    this.props.firebase.doUpdateFeed(feed).catch((error) => {
      console.log(error)
    })
  }

  onSnooze = (removeIndex) => {
    let { feed } = this.state

    feed.splice(removeIndex, 1)

    this.setState({ feed: feed })

    this.props.firebase.doUpdateFeed(feed).catch((error) => {
      console.log(error)
    })
  }

  onEdit = (tweetText, index) => {
    let { feed } = this.state
    let updatedFeed = [...feed]
    updatedFeed[index].text = tweetText

    this.setState({ feed: updatedFeed })

    this.props.firebase.doUpdateFeed(updatedFeed).catch((error) => {
      console.log(error)
    })
  }

  render() {
    const { feed, tweetsLoaded, subscriptionLevel, modalOpen } = this.state

    return (
      <div className="w-full p-6">
        <UpgradeModal
          open={modalOpen}
          setOpen={() => this.setState({ modalOpen: false })}
          startCheckoutSession={() => this.props.firebase.doStartCheckoutSession()}
        />
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
          theme="colored"
        />
        {tweetsLoaded ? (
          <div className="w-full">
            {feed.length === 0 ? (
              <div>
                <div
                  className="px-16 py-12 mb-3 bg-gray-800 rounded-2xl"
                  style={{
                    background: '#423D35',
                    color: '#FFC559',
                    border: '1px solid #272737',
                    boxShadow: '0px 1px 10px #28241C',
                  }}
                >
                  <h2
                    className="mb-4 font-semibold"
                    style={{
                      color: '#FFC559',
                    }}
                  >
                    Loading Tweets...
                  </h2>
                  <p
                    className="mb-4 text-gray-400"
                    style={{
                      color: '#EFC559',
                    }}
                  >
                    This is where your tweets will populate, which can take up to 15 minutes. If you
                    don’t see any tweets after 15 minutes, you might not have enough original tweets
                    (retweets don’t work).
                  </p>
                  <p
                    className="text-gray-400"
                    style={{
                      color: '#EFC559',
                    }}
                  >
                    If you think you have plenty of original tweets but still don’t see any tweets
                    here, email us at{' '}
                    <a className="underline" href="mailto:team@timeless.so">
                      team@timeless.so
                    </a>{' '}
                    so we can investigate.
                  </p>
                </div>
              </div>
            ) : (
              <div className="w-full">
                {feed.map((tweet, i) => (
                  <div className="p-6 mb-3 bg-gray-800 shadow-xl rounded-2xl" key={i}>
                    <div className="p-4 pt-2 pb-6 text-gray-300 whitespace-pre-wrap">
                      <ContentEditable
                        html={tweet.text}
                        disabled={subscriptionLevel !== 'pro'}
                        onChange={(event) => this.onEdit(event.target.value, i)}
                      />
                    </div>
                    <div className="flex items-baseline p-2">
                      {subscriptionLevel === 'pro' ? (
                        <IconContext.Provider value={{ color: '#8B99A5', size: '0.9em' }}>
                          <div className="flex items-center ml-2">
                            <FaRegComment />
                            <p className="pl-2 mr-4 text-xs text-gray-400">
                              {tweet.public_metrics.reply_count}
                            </p>
                            <FiRepeat />
                            <p className="pl-2 mr-4 text-xs text-gray-400">
                              {tweet.public_metrics.retweet_count +
                                tweet.public_metrics.quote_count}
                            </p>
                            <FaRegHeart />
                            <p className="pl-2 mr-4 text-xs text-gray-400">
                              {tweet.public_metrics.like_count}
                            </p>
                            {tweet.created_at && (
                              <p className="pl-2 text-xs text-gray-400">
                                {formatDistance(new Date(tweet.created_at), new Date(), {
                                  addSuffix: true,
                                })}
                              </p>
                            )}
                          </div>
                        </IconContext.Provider>
                      ) : (
                        <div className="ml-2">
                          <button
                            onClick={() => this.setState({ modalOpen: true })}
                            className="mr-2 text-xs font-light text-gray-400 underline"
                          >
                            Edit Tweet
                          </button>
                          <button
                            onClick={() => this.setState({ modalOpen: true })}
                            className="text-xs font-light text-gray-400 underline"
                          >
                            View Metrics
                          </button>
                        </div>
                      )}
                      <div className="flex-1"></div>
                      <button
                        className="flex items-center px-3 py-2 mr-2 text-xs transition duration-300 ease-in-out bg-gray-900 rounded-lg hover:bg-gray-700"
                        name={i}
                        onClick={() => this.onDelete(i)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 mr-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="#557"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                        <p className="text-gray-400">Delete</p>
                      </button>
                      <button
                        className="flex items-center px-3 py-2 mr-2 text-xs transition duration-300 ease-in-out bg-gray-900 rounded-lg hover:bg-gray-700"
                        name={i}
                        onClick={() => this.onSnooze(i)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 mr-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="#557"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <p className="text-gray-400">Snooze</p>
                      </button>
                      <button
                        className="flex items-center px-3 py-2 text-xs transition duration-300 ease-in-out bg-gray-900 rounded-lg hover:bg-gray-800"
                        style={{ border: '1px solid #7560F5' }}
                        name={i}
                        onClick={() => this.onAddToQueue(i)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 mr-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="#7560F5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <p style={{ color: '#7560F5' }}>Add to Queue</p>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <SkeletonTheme color="#1F2937" highlightColor="#303040">
            <p>
              <Skeleton
                duration={2}
                count={3}
                height={150}
                className="mb-3"
                style={{ borderRadius: 20 }}
              />
            </p>
          </SkeletonTheme>
        )}
      </div>
    )
  }
}

const condition = (authUser) => !!authUser

const HomePage = compose(withFirebase, withAuthorization(condition))(Dashboard)

export default HomePage
